import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import SkeletonCardDiscussion from "../../../skeletons/SkeletonCardDiscussion/SkeletonCardDiscussion";
import CardDiscussion from "../CardDiscussion";
import { useSelector } from "react-redux";

const SectionMyDiscussion = () => {
    const { myDiscussions, loadingDiscussion } = useSelector(state => state.discussion)

    const [limit, setLimit] = useState(5)
    const all = myDiscussions;
    const unanswered = myDiscussions.filter(discussion => discussion.total_answer < 1 && discussion.mark !== 'solved');
    const answered = myDiscussions.filter(discussion => discussion.total_answer > 0 && discussion.mark !== 'solved');
    const solved = myDiscussions.filter(discussion => discussion.mark === 'solved');

    return (
        <section className="section mb-5">
            <h4 className="section-title mb-3">My Questions</h4>

            <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active px-3" id="pills-myall-tab" data-bs-toggle="pill" data-bs-target="#pills-myall" type="button" role="tab" aria-controls="pills-myall" aria-selected="true">All ({all.length})</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-3" id="pills-myunanswered-tab" data-bs-toggle="pill" data-bs-target="#pills-myunanswered" type="button" role="tab" aria-controls="pills-myunanswered" aria-selected="false">Unanswered ({unanswered.length})</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-3" id="pills-myanswered-tab" data-bs-toggle="pill" data-bs-target="#pills-myanswered" type="button" role="tab" aria-controls="pills-myanswered" aria-selected="false">Answered ({answered.length})</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-3" id="pills-mysolved-tab" data-bs-toggle="pill" data-bs-target="#pills-mysolved" type="button" role="tab" aria-controls="pills-mysolved" aria-selected="false">Solved ({solved.length})</button>
                </li>
            </ul>

            <div className="tab-content my-2" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-myall" role="tabpanel" aria-labelledby="pills-myall-tab">
                    {loadingDiscussion
                        ? [...Array(limit)].map((item, index) => {
                            return (
                                <SkeletonCardDiscussion key={index} />
                            );
                        })
                        : all?.length < 1
                            ? (<div className="bg-white text-center text-muted p-3 fw-bold">Belum ada pertanyaan</div>)
                            : all?.map((discussion, index) => {
                                return (
                                    <Link key={index} className="link" to={`${process.env.REACT_APP_URL}/forum/thread/${discussion.slug}`}>
                                        <CardDiscussion
                                            avatar={discussion.avatar}
                                            name={discussion.name}
                                            rank={discussion?.rank?.rank_name}
                                            job={discussion.jobs}
                                            role={discussion.role_name}
                                            subject={discussion.subject}
                                            mark={discussion.mark}
                                            date={discussion.date}
                                            courseTitle={discussion.course_title}
                                            courseSlug={discussion.course_slug}
                                            lessonTitle={discussion.lesson_title}
                                            lessonId={discussion.lesson_id}
                                            totalAnswer={discussion.total_answer}
                                            tags={discussion.tags}
                                        />
                                    </Link>
                                )

                            }).slice(0, limit)}
                    {all?.length > limit && (
                        <div className="text-center my-3">
                            <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                        </div>
                    )}
                </div>

                <div className="tab-pane fade" id="pills-myunanswered" role="tabpanel" aria-labelledby="pills-myunanswered-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : unanswered?.length < 1
                                ? (<div className="bg-white text-center text-muted p-3 fw-bold">Belum ada pertanyaan</div>)
                                : unanswered?.map((discussion, index) => {
                                    return (
                                        <Link key={index} className="link" to={`${process.env.REACT_APP_URL}/forum/thread/${discussion.slug}`}>
                                            <CardDiscussion
                                                avatar={discussion.avatar}
                                                name={discussion.name}
                                                rank={discussion?.rank?.rank_name}
                                                job={discussion.jobs}
                                                role={discussion.role_name}
                                                subject={discussion.subject}
                                                mark={discussion.mark}
                                                date={discussion.date}
                                                courseTitle={discussion.course_title}
                                                courseSlug={discussion.course_slug}
                                                lessonTitle={discussion.lesson_title}
                                                lessonId={discussion.lesson_id}
                                                totalAnswer={discussion.total_answer}
                                                tags={discussion.tags}
                                            />
                                        </Link>
                                    )

                                }).slice(0, limit)
                    }
                    {
                        unanswered?.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>

                <div className="tab-pane fade" id="pills-myanswered" role="tabpanel" aria-labelledby="pills-myanswered-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : answered?.length < 1
                                ? (<div className="bg-white text-center text-muted p-3 fw-bold">Belum ada pertanyaan</div>)
                                : answered.map((discussion, index) => {
                                    return (
                                        <Link key={index} className="link" to={`${process.env.REACT_APP_URL}/forum/thread/${discussion.slug}`}>
                                            <CardDiscussion
                                                avatar={discussion.avatar}
                                                name={discussion.name}
                                                rank={discussion?.rank?.rank_name}
                                                job={discussion.jobs}
                                                role={discussion.role_name}
                                                subject={discussion.subject}
                                                mark={discussion.mark}
                                                date={discussion.date}
                                                courseTitle={discussion.course_title}
                                                courseSlug={discussion.course_slug}
                                                lessonTitle={discussion.lesson_title}
                                                lessonId={discussion.lesson_id}
                                                totalAnswer={discussion.total_answer}
                                                tags={discussion.tags}
                                            />
                                        </Link>
                                    )

                                }).slice(0, limit)
                    }
                    {
                        answered?.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>

                <div className="tab-pane fade" id="pills-mysolved" role="tabpanel" aria-labelledby="pills-mysolved-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : solved?.length < 1
                                ? (<div className="bg-white text-center text-muted p-3 fw-bold">Belum ada pertanyaan</div>)
                                : solved?.map((discussion, index) => {
                                    return (
                                        <Link key={index} className="link" to={`${process.env.REACT_APP_URL}/forum/thread/${discussion.slug}`}>
                                            <CardDiscussion
                                                avatar={discussion.avatar}
                                                name={discussion.name}
                                                rank={discussion?.rank?.rank_name}
                                                job={discussion.jobs}
                                                role={discussion.role_name}
                                                subject={discussion.subject}
                                                mark={discussion.mark}
                                                date={discussion.date}
                                                courseTitle={discussion.course_title}
                                                courseSlug={discussion.course_slug}
                                                lessonTitle={discussion.lesson_title}
                                                lessonId={discussion.lesson_id}
                                                totalAnswer={discussion.total_answer}
                                                tags={discussion.tags}
                                            />
                                        </Link>
                                    )

                                }).slice(0, limit)
                    }
                    {
                        solved?.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default SectionMyDiscussion;
