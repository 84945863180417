import { Helmet } from 'react-helmet-async';
import Layout from "../../components/global/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import SectionLevel from "../../components/learn/Section/SectionLevel/SectionLevel";
import { useEffect } from 'react';
import { getMyRoadmaps } from '../../features/roadmaps/roadmapSlice';
import { getMyCourses } from '../../features/courses/courseSlice';
import { getLatestLearn } from '../../features/learn/learnSlice';
import { getLogsCertificates } from '../../features/user/certificatesSlice';
import SectionCardStats from '../../components/learn/Section/SectionCardStats/SectionCardStats';
import SectionlatestPurchase from '../../components/learn/Section/SectionLatestPurchase/SectionlatestPurchase';
import { getLatestPayments } from '../../features/payment/paymentSlice';
import SectionStatistic from '../../components/learn/Section/SectionStatistic/SectionStatistic';
import SectionDiscussions from '../../components/learn/Section/SectionDiscussions/SectionDiscussions';
import { getAllDiscussion } from '../../features/discussion/discussionSlice';
import SectionSuggestedArticles from '../../components/learn/Section/SectionSuggestedArticles/SectionSuggestedArticles';
import { getAllTutorial } from '../../features/tutorials/tutorialSlice';
import { getCurrentUserPoint, getLeaderboard } from '../../features/user/leaderboardSlice';
import { getLessonStats } from '../../features/lessons/lessonSlice';
import SectionContinueLearning from '../../components/learn/Section/SectionContinueLearning/SectionContinueLearning';

const LearnOverview = () => {
    const { coursePage } = useSelector((state) => ({ ...state.courses }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLatestPayments());
        dispatch(getLatestLearn());
        dispatch(getMyRoadmaps());
        dispatch(getMyCourses(coursePage));
        dispatch(getLogsCertificates());
        dispatch(getAllDiscussion());
        dispatch(getAllTutorial(5));
        dispatch(getLeaderboard('week'));
        dispatch(getCurrentUserPoint('week'));
        dispatch(getLessonStats());
    }, [dispatch, coursePage]);

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Layout>
                <section className="section py-5" id="learnHome">
                    <div className="container-fluid">
                        <div className="row">
                            {/* Main */}
                            <div className="col-md-8 order-last order-md-first">
                                <SectionContinueLearning />
                                <SectionCardStats />
                                <SectionStatistic />
                                <SectionDiscussions />
                            </div>
                            {/* End of main */}

                            {/* Sidebar */}
                            <div className="col-md-4 ps-lg-5">
                                <section className="mb-4 mb-xl-5 mt-xl-5 pt-xl-2">
                                    <a href="/learn/courses/detail/panduan-member-codepolitan" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src="https://image.web.id/images/how-to-learn-at-codepolitan.png" alt="How to learn at codepolitna" />
                                    </a>
                                </section>
                                <SectionLevel />
                                <SectionlatestPurchase />
                                {/* <SectionTasks /> */}
                                <SectionSuggestedArticles />
                            </div>
                            {/* End of Sidebar */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default LearnOverview;
