import { Helmet } from 'react-helmet-async';
import CardNotification from "../../components/global/Cards/CardNotification/CardNotification";
import Layout from "../../components/global/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getNotifications } from "../../features/notifications/notificationSlice";
import SkeleteonCardNotification from "../../components/skeletons/SkeletonCardNotification/SkeletonCardNotification";

const NotificationsPage = () => {
    const [limit, setLimit] = useState(10);
    const { notifications, loadingNotification } = useSelector((state) => state.notifications);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNotifications());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Notifications</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <h1 className="section-title h2 mb-3 h4">Notifications</h1>

                        <div className="row">
                            {loadingNotification && [1, 2, 3].map(item => (
                                <div className="col-12 mb-2" key={item}>
                                    <SkeleteonCardNotification />
                                </div>
                            ))}
                            {!loadingNotification && notifications.map((notification, index) => (
                                <div className="col-12 mb-2" key={index}>
                                    <CardNotification
                                        type={notification.type}
                                        message={notification.meta.message}
                                        date={notification.date}
                                        slug={notification.meta.slug}
                                    />
                                </div>
                            )).slice(0, limit)}
                        </div>
                        {notifications.length > limit && (
                            <div className="row my-3">
                                <div className="col text-center">
                                    <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary">Show more</button>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default NotificationsPage;
