import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';
import { useSelector } from "react-redux";
import Layout from "../../../components/global/Layout/Layout";
import Sidebar from "../../../components/Settings/Sidebar/Sidebar";
import DropdownMenu from "../../../components/Settings/DropdownMenu/DropdownMenu";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserByUsername } from "../../../features/user/userSlice";

const Interface = () => {
    const { myUser } = useSelector((state) => ({ ...state.user }));
    const dispatch = useDispatch();

    useEffect(() => {
        if (myUser?.username) {
            dispatch(getUserByUsername(myUser?.username));
        }
    }, [dispatch, myUser?.username]);

    return (
        <>
            <Helmet>
                <title>Interface</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row my-5">
                            <div className="col-lg-2 d-none d-lg-block">
                                <Sidebar />
                            </div>
                            <div className="col-lg-10">
                                <div className="d-lg-none mb-3">
                                    <DropdownMenu />
                                </div>
                                <div className="bg-white p-3">
                                    <div className="card border-0 mb-3">
                                        <div className="card-header border-0">
                                            <h5 className="text-muted my-2">Interface</h5>
                                        </div>
                                        <div className="card-body text-muted">
                                            <p className="fw-bolder">Mode Dashboard</p>
                                            <div className="mb-2">
                                                <FontAwesomeIcon className="text-primary me-2" icon={faLaptopCode} /> New Dashboard <span className="badge border border-primary text-primary rounded-pill ms-2">&#8226; Active</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout >
        </>
    );
};

export default Interface;
