import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLogsCertificates } from '../../../features/user/certificatesSlice';
import { getPortfolios } from '../../../features/user/portfoliosSlice';
import { getOccupations } from '../../../features/user/occupationSlice';
import { getEducations } from '../../../features/user/educationsSlice';
import { getSkills } from '../../../features/user/skillSlice';
import { getMyUser } from '../../../features/user/userSlice';

const CV = () => {
    const ref = useRef();
    const dispatch = useDispatch();

    const { myUser, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { occupations } = useSelector((state) => ({ ...state.occupation }));
    const { educations } = useSelector((state) => ({ ...state.education }));
    const { certificates } = useSelector((state) => ({ ...state.certificates }));
    const { skills } = useSelector((state) => ({ ...state.skill }));
    const { portfolios } = useSelector((state) => ({ ...state.portfolio }));

    useEffect(() => {
        dispatch(getMyUser());
        dispatch(getLogsCertificates());
        dispatch(getPortfolios());
        dispatch(getOccupations());
        dispatch(getEducations());
        dispatch(getSkills());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>CV</title>
            </Helmet>
            <Layout>
                <section className="section my-5">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between mb-5">
                            <Link className="link ms-2" to={`${process.env.REACT_APP_URL}/coders/${myUser?.username}`}>
                                <FontAwesomeIcon icon={faChevronLeft} /> Back to Profile
                            </Link>
                            <ReactToPrint
                                trigger={() => <button className="btn btn-outline-primary"><FontAwesomeIcon icon={faDownload} /> Download CV</button>}
                                content={() => ref.current}
                                bodyClass="container-fluid"
                            />
                        </div>
                        {loadingUser ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <span className="ms-3">Loading...</span>
                            </div>
                        ) : null}
                        {!loadingUser ? (
                            <div className="shadow">
                                <div className="row border-bottom border-5 border-primary" ref={ref}>
                                    <div className="col-4 px-0 py-4" style={{ background: '#142A36' }}>
                                        <div className="my-3">
                                            <img height={150} width={150} className="img-fluid rounded-circle objectfit-cover d-block mx-auto" src={myUser?.avatar || "/assets/img/placeholder.jpg"} alt="Profile" />
                                            <section className="text-white mb-3">
                                                <div className="px-0 my-3 border-start border-primary border-5" style={{ background: '#132029' }}>
                                                    <div className="px-3 py-2">
                                                        <h5 className="mb-0">Hubungi</h5>
                                                    </div>
                                                </div>
                                                <div className="px-4">
                                                    <ul className="list-group">
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Email</p>
                                                            <p className="text-break mb-1">{myUser?.email || '-'}</p>
                                                        </li>
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Whatsapp</p>
                                                            <p className="text-break mb-1">{myUser?.phone || '-'}</p>
                                                        </li>
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Alamat</p>
                                                            <p className="text-break mb-1">{myUser?.address || '-'}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </section>
                                            <section className="text-white mb-3">
                                                <div className="px-0 my-3 border-start border-primary border-5" style={{ background: '#132029' }}>
                                                    <div className="px-3 py-2">
                                                        <h5 className="mb-0">Sosial Media</h5>
                                                    </div>
                                                </div>
                                                <div className="px-4">
                                                    <ul className="list-group">
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Linkedin</p>
                                                            <p className="text-break mb-1">{myUser?.linkedin || '-'}</p>
                                                        </li>
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Facebook</p>
                                                            <p className="text-break mb-1">{myUser?.facebook || '-'}</p>
                                                        </li>
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Instagram</p>
                                                            <p className="text-break mb-1">{myUser?.instagram || '-'}</p>
                                                        </li>
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Github</p>
                                                            <p className="text-break mb-1">{myUser?.github || '-'}</p>
                                                        </li>
                                                        <li className="list-group-item bg-transparent text-white border-0 px-0 py-1">
                                                            <p className="mb-0 fw-bolder">Website</p>
                                                            <p className="text-break mb-1">{myUser?.web || '-'}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </section>
                                            <section className="text-white mb-3">
                                                <div className="px-0 my-3 border-start border-primary border-5" style={{ background: '#132029' }}>
                                                    <div className="px-3 py-2">
                                                        <h5 className="mb-0">Keahlian</h5>
                                                    </div>
                                                </div>
                                                <div className="px-4">
                                                    <ul className="list-group">
                                                        {skills?.map((skill, index) => {
                                                            return (
                                                                <li className="list-group-item bg-transparent text-white border-0 px-0 py-1" key={index}>
                                                                    <span className="text-capitalize">{skill.skill} - {skill.level}</span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </section>
                                            <section className="text-white">
                                                <hr />
                                                <img className="img-fluid d-block mx-auto my-5" src={`https://quickchart.io/qr?text=https://www.codepolitan.com/coders/${myUser?.username}`} alt="QR" />
                                                <p className="text-center w-75 mx-auto"><small>This CV was generated by CODEPOLITAN</small></p>
                                            </section>
                                        </div>
                                    </div>
                                    <div className="col-8 bg-white px-0 py-4">
                                        <div className="bg-primary text-white p-4 my-3">
                                            <h2 className="fw-bolder">{myUser?.name}</h2>
                                            <hr className="w-50" style={{ height: '3px', opacity: '80%' }} />
                                            <p className="fw-bolder mb-auto">{myUser?.jobs}</p>
                                        </div>
                                        <div className="p-4">
                                            <section className="mb-3">
                                                <h5 className="mb-3">Tentang Saya</h5>
                                                <p>{myUser?.short_description}</p>
                                            </section>
                                            <hr />
                                            <section className="mb-3">
                                                <h5 className="mb-3">Pengalaman</h5>
                                                {occupations?.map((occupation, index) => {
                                                    console.log(occupation)
                                                    return (
                                                        <div className="mb-4" key={index}>
                                                            <h6 className="mb-1 fw-bolder">{occupation.title}</h6>
                                                            <p className="mb-1">{occupation.company} | {formatDate(occupation.date_start)} - {occupation.date_end !== 'undefined' ? formatDate(occupation.date_end) : 'sekarang'}</p>
                                                            <p className="mb-0">{occupation.description}</p>
                                                        </div>
                                                    );
                                                })}
                                                <hr />
                                            </section>
                                            <section className="mb-3">
                                                <h5 className="mb-3">Proyek</h5>
                                                {portfolios?.map((portfolio, index) => {
                                                    return (
                                                        <div className="mb-4" key={index}>
                                                            <h6 className="fw-bolder">{portfolio.project_name}</h6>
                                                            <p className="mb-0">
                                                                Role: {portfolio.role_name} | {portfolio.year}
                                                            </p>
                                                            <p className="mb-0">Attachment: {portfolio.attachment}</p>
                                                        </div>
                                                    );
                                                })}
                                                <hr />
                                            </section>
                                            <section className="mb-3">
                                                <h5 className="mb-3">Pendidikan</h5>
                                                {educations?.map((education, index) => {
                                                    return (
                                                        <div className="mb-4" key={index}>
                                                            <h6 className="fw-bolder mb-1">{education.major}</h6>
                                                            <p className="mb-0">{education.institution}, {education.graduated_at}</p>
                                                        </div>
                                                    );
                                                })}
                                                <hr />
                                            </section>
                                            <section className="mb-3">
                                                <h5 className="mb-3">Sertifikat</h5>
                                                {certificates?.map((certificate, index) => {
                                                    return (
                                                        <div className="mb-4" key={index}>
                                                            <h6 className="fw-bolder mb-1">{certificate.cert_title}</h6>
                                                            <p className="mb-0">Diterbitkan oleh Codepolitan pada {formatDate(certificate.created_at)}</p>
                                                            <p>{`https://codepolitan.com/c/${certificate.code}`}</p>
                                                        </div>
                                                    );
                                                })}
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default CV;
