import { faAward, faMap, faSwatchbook, faTrophy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Skeleton from "react-loading-skeleton"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const SectionCardStats = () => {
    const { myUser } = useSelector((state) => ({ ...state.user }));
    const { myCourses, loadingCourse } = useSelector((state) => ({ ...state.courses }));
    const { certificates, loadingCertificate } = useSelector((state) => ({ ...state.certificates }));
    const { myRoadmaps, loadingRoadmap } = useSelector((state) => ({ ...state.roadmap }));
    const { leaderboard, loadingLeaderboard, currentUserPoint } = useSelector(state => state.leaderboards);

    let position = leaderboard?.findIndex(item => item.name === currentUserPoint?.name);
    let suffix = '';

    if (position + 1 === 1) {
        suffix = 'st';
    } else if (position + 1 === 2) {
        suffix = 'nd';
    } else if (position + 1 === 3) {
        suffix = 'rd';
    } else if (position + 1 >= 4) {
        suffix = 'th';
    } else {
        suffix = '';
    };

    return (
        <section className="section mb-4">
            <h4 className="section-title mb-4">Numbers</h4>
            <div className="row">
                <div className="col-md-6 col-xl-3 p-2">
                    <div className="card border-0 rounded-2 bg-primary text-white h-100" style={{ background: `url('https://image.web.id/images/Mask-group-1.png') right center no-repeat`, backgroundSize: '7em' }}>
                        <div className="card-body">
                            <FontAwesomeIcon size="2x" icon={faSwatchbook} />
                            <div className="mt-4">
                                {loadingCourse ? (
                                    <Skeleton count={1} className="bg-white h-100" width={50} height={40} />
                                ) : (
                                    <h2 className="fw-bold">{myCourses?.length > 50 && ">"}{myCourses?.length || 0}</h2>
                                )}
                                <p>Courses</p>
                            </div>
                            <hr />
                            <div className="text-end">
                                <Link className="text-white small" to="/learn/courses">Lihat Course</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3 p-2">
                    <div className="card border-0 rounded-2 bg-pink text-white h-100" style={{ background: `url('https://image.web.id/images/Mask-group-2.png') right center no-repeat`, backgroundSize: '7em' }}>
                        <div className="card-body">
                            <FontAwesomeIcon size="2x" icon={faMap} />
                            <div className="mt-4">
                                {loadingRoadmap ? (
                                    <Skeleton count={1} className="bg-white h-100" width={50} height={40} />
                                ) : (
                                    <h2 className="fw-bold">{myRoadmaps?.length || 0}</h2>
                                )}
                                <p>Roadmaps</p>
                            </div>
                            <hr />
                            <div className="text-end">
                                <Link className="text-white small" to="/learn/courses">Lihat Roadmap</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3 p-2">
                    <div className="card border-0 rounded-2 bg-blue text-white h-100" style={{ background: `url('https://image.web.id/images/Mask-group-3.png') right center no-repeat`, backgroundSize: '7em' }}>
                        <div className="card-body">
                            <FontAwesomeIcon size="2x" icon={faAward} />
                            <div className="mt-4">
                                {loadingCertificate ? (
                                    <Skeleton count={1} className="bg-white h-100" width={50} height={40} />
                                ) : (
                                    <h2 className="fw-bold">{certificates?.length || 0}</h2>
                                )}
                                <p>Certificates</p>
                            </div>
                            <hr />
                            <div className="text-end">
                                <Link className="text-white small" to={`${process.env.REACT_APP_URL}/coders/${myUser?.username}`}>Lihat Certificate</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3 p-2">
                    <div className="card border-0 rounded-2 bg-yellow text-white h-100" style={{ background: `url('https://image.web.id/images/Mask-group-4.png') right center no-repeat`, backgroundSize: '7em' }}>
                        <div className="card-body">
                            <FontAwesomeIcon size="2x" icon={faTrophy} />
                            <div className="mt-4">
                                {loadingLeaderboard ? (
                                    <Skeleton count={1} className="bg-white h-100" width={50} height={40} />
                                ) : (
                                    <h2 className="fw-bold">{position + 1 < 1 ? 'N/A' : position + 1}<sup className="fw-normal" style={{ fontSize: '18px' }}>{suffix}</sup></h2>
                                )}
                                <p>Weekly Ranks</p>
                            </div>
                            <hr />
                            <div className="text-end">
                                <Link className="text-white small" to="/learn/leaderboard">Lihat Leaderboard</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionCardStats